import { AbortControllerFacade } from '../abort-controller';
import { requestWithAbort, type ServiceRequestWithAbort } from '../use-service';

import { TeamsPermissionApi, type TeamsPermissions, type TeamsPermissionsApi } from './types';

export type RequestPermissionsUrlParams =
	| { apiVersion: 'v3'; siteId?: never }
	| { apiVersion: 'v4'; siteId: string };

export const urlGetPermissions = ({
	orgId,
	siteId,
	apiVersion,
}: RequestPermissionsUrlParams & { orgId: string }): string =>
	`/gateway/api/${apiVersion}/teams/permission/self/organization/${orgId}${
		apiVersion === 'v4' ? `?siteId=${siteId}` : ''
	}`;

export const requestPermissions = ({
	orgId,
	...props
}: RequestPermissionsUrlParams & {
	orgId?: string;
}): ReturnType<ServiceRequestWithAbort> =>
	orgId
		? requestWithAbort(urlGetPermissions({ ...props, orgId }), {
				method: 'GET',
			})
		: [() => Promise.resolve(), new AbortControllerFacade()];

export const transformPermissions = (permissions: TeamsPermissionsApi): TeamsPermissions => {
	return permissions.reduce<TeamsPermissions>(
		(acc, permission) => {
			switch (permission) {
				case TeamsPermissionApi.canCreateTeams:
					return { ...acc, canCreateTeams: true };
				case TeamsPermissionApi.canViewTeams:
					return { ...acc, canViewTeams: true };
				case TeamsPermissionApi.canAdminTeams:
					return { ...acc, canAdminTeams: true };
				default:
					return acc;
			}
		},
		{
			canCreateTeams: false,
			canViewTeams: false,
			canAdminTeams: false,
		},
	);
};

/**
 * Simple caching for a promise
 */
export class PermissionsCachingClient {
	request: (() => Promise<void>) | null = null;
	response: Promise<void> | null = null;
	constructor() {}

	async setRequest(request: () => Promise<void>) {
		this.request = request;
	}

	async getPermissions() {
		if (!this.request) {
			return;
		}
		if (!this.response) {
			this.response = this.request();
		}
		return this.response;
	}
}
