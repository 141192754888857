import { useCallback, useEffect, useRef, useState } from 'react';

import type { LazyService, Service, ServiceResult, UseServiceLightResult } from './types';
import { HttpError } from './utils';

export const useLazyService = <TData, TError extends Error = Error>(
	...[request, controller, options]: Parameters<Service<TData, TError>>
): ReturnType<LazyService<TData, TError>> => {
	const [state, setState] = useState<ServiceResult<TData, TError>>({
		loading: false,
		error: undefined,
		data: undefined,
		...options?.initialState,
	});

	const getData = useCallback(async () => {
		setState((s) => ({
			...s,
			loading: true,
		}));

		try {
			const response = await request();
			const traceId = response?.headers?.get('atl-traceid');
			let jsonResponse: TData | undefined;
			let isResponseText = false;
			const resText = (await response?.text()) || '{}';
			try {
				jsonResponse = JSON.parse(resText);
			} catch {
				isResponseText = true;
			}

			if (!response?.ok || isResponseText) {
				if (options?.ErrorHandler) {
					throw new options.ErrorHandler({
						...jsonResponse,
						traceId,
						status: response?.status,
						statusText: response?.statusText || resText,
					});
				} else {
					throw new HttpError(response?.status, response?.statusText || resText, traceId);
				}
			}

			setState((s) => ({
				...s,
				loading: false,
				data: jsonResponse,
			}));

			return response;
		} catch (error) {
			setState((s) => ({
				...s,
				loading: false,
				error: error as TError,
			}));
		}
	}, [request, options?.ErrorHandler]);

	useEffect(() => {
		return () => {
			controller.abort();
		};
	}, [controller]);

	return [getData, state];
};

export const useService = <TData, TError extends Error = Error>(
	...[request, controller, options]: Parameters<Service<TData, TError>>
): ReturnType<Service<TData, TError>> => {
	const [getData, state] = useLazyService<TData, TError>(request, controller, {
		...options,
		initialState: { ...options?.initialState, loading: true },
	});

	useEffect(() => {
		getData();
	}, [getData]);

	return state;
};

export const useServiceLight = <TData, TError extends Error = Error>(
	request: () => Promise<TData>,
	initialState: Partial<ServiceResult<TData, TError>> = {},
): UseServiceLightResult<TData, TError> => {
	const [state, setStateRaw] = useState<ServiceResult<TData, TError>>({
		loading: false,
		...initialState,
	});

	const isMounted = useRef(true);

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	});

	const setState = useCallback((state: ServiceResult<TData, TError>) => {
		if (isMounted.current) {
			setStateRaw(state);
		}
	}, []);

	const fetchData = useCallback(async () => {
		setState({
			loading: true,
			error: undefined,
			data: undefined,
		});

		try {
			const data: TData = await request();

			setState({
				loading: false,
				error: undefined,
				data,
			});
		} catch (error: any) {
			setState({
				loading: false,
				error,
				data: undefined,
			});
		}
	}, [request, setState]);

	return { ...state, fetchData };
};
