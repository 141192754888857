import { useEffect, useMemo } from 'react';

import { createHook, createStore } from 'react-sweet-state';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';

import { LegionError } from '../../utils/legion';
import { fireOperationalEvent } from '../analytics';
import { useLazyService } from '../use-service';

import {
	type TeamsPermissionsActions,
	type TeamsPermissionsResponse,
	type TeamsPermissionsService,
	type TeamsPermissionsStore,
} from './types';
import { PermissionsCachingClient, requestPermissions, transformPermissions } from './utils';

const Store = createStore<TeamsPermissionsStore, TeamsPermissionsActions>({
	initialState: {
		error: undefined,
		hasLoaded: false,
		isLoading: false,
		permissions: {
			canCreateTeams: true,
			canViewTeams: true,
			canAdminTeams: false,
		},
	},
	actions: {
		setError:
			(error) =>
			({ setState }) => {
				setState({ hasLoaded: true, isLoading: false, error });
			},
		setLoading:
			(isLoading) =>
			({ setState }) => {
				setState({ isLoading });
			},
		setPermissions:
			(permissions) =>
			({ setState }) => {
				setState({
					hasLoaded: true,
					isLoading: false,
					permissions: transformPermissions(permissions),
				});
			},
	},
	name: 'teams-permissions',
});

const useTeamsPermissionsStore = createHook(Store);

export const useTeamsPermissionsService: TeamsPermissionsService = (
	scope,
	{ enabled, initialState, attributes } = { enabled: false, initialState: {}, attributes: {} },
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// backwards compatibility allow orgId to be passed as a string
	const { orgId, cloudId } =
		typeof scope === 'string' || !scope ? { orgId: scope, cloudId: undefined } : scope;

	const [state, { setError, setLoading, setPermissions }] = useTeamsPermissionsStore();

	const [request, controller] = useMemo(
		() =>
			requestPermissions({
				orgId,
				apiVersion: 'v4',
				siteId: cloudId || 'None',
			}),
		[orgId, cloudId],
	);

	const [getPermissions, { loading, error, data }] = useLazyService<
		TeamsPermissionsResponse,
		LegionError
	>(request, controller, { ErrorHandler: LegionError, initialState });

	const isLoadingAnywhere = state.isLoading || loading;
	const hasLoadedAnywhere = state.error || state.hasLoaded || !!data || !!error;
	const willSendRequest = orgId && enabled && !hasLoadedAnywhere && !isLoadingAnywhere;

	const analyticAttributes = useMemo(
		() => ({
			userId: attributes?.userId,
			orgId,
			cloudId,
		}),
		[attributes?.userId, orgId, cloudId],
	);

	useEffect(() => {
		const fetchData = async () => {
			if (willSendRequest) {
				// There is a race condition here with RSS where the request can be sent twice, so using the client to prevent that
				if (fg('pf-directory-settings-query-migration')) {
					clientWithCaching.setRequest(async () => {
						await getPermissions();
					});
				}
				setLoading(true);
				fireOperationalEvent(createAnalyticsEvent, {
					action: 'fetched',
					actionSubject: 'use-team-permissions',
					attributes: analyticAttributes,
				});
				if (fg('pf-directory-settings-query-migration')) {
					await clientWithCaching.getPermissions();
				} else {
					await getPermissions();
				}
			}
		};

		fetchData();

		return () => {
			setLoading(false);
		};
	}, [
		getPermissions,
		setLoading,
		createAnalyticsEvent,
		orgId,
		cloudId,
		analyticAttributes,
		willSendRequest,
	]);

	useEffect(() => {
		if (data?.permissions) {
			setPermissions(data.permissions);
		}

		if (error) {
			setError(error);
		}
	}, [data, error, setPermissions, setError]);

	return {
		loading,
		data,
		...state,
	};
};

const clientWithCaching = new PermissionsCachingClient();
